export const enUS_verify = {
  'verify.title': 'Check & Verify Your Details',
  'verify.subTitle': 'Please check your bank and personal details below and ensure they match one another',
  'verify.card.bank.business.details': 'Bank Business Details',
  'verify.card.bank.payment.details': 'Bank Payment Details',
  'verify.account.name': 'Name',
  'verify.account.bsb': 'BSB',
  'verify.account.accountNo': 'Account No',
  'verify.section.error':
    'The details you have provided do not match the ones verified by your bank, please amend the correct information verified by your bank.',
  'verify.systemError': 'System Error',
};
