import dev from './development';
import prod from './production';

export interface IConfig {
  API: {
    USER_SERVICE_LOGIN: string;
    USER_SERVICE_SIGNUP: string;
    CONTACT: string;
    VERIFIED: string;
    CONSENT: string;
    ABR_LOOKUP: string;
    ONBOARDING: string;
    TERM_ACCEPT: string;
    BUSINESS_DETAILS: string;
    PAYMENT_DETAILS: string;
    VERIFY_DETAILS: string;
    VERIFY_COMPLETE: string;
    RESET_PASSWORD: string;
    NEW_PASSWORD: string;
    CONSENT_MANUAL: string;
    UPLOAD: string;
    QUESTIONNAIRE: string;
    VALIDATE_BSB: string;
    CONSENT_AUTHORIZATION: string;
    CONSENT_ACTIVE: string;
    OPENBANKING_VERIFIED_DETAIL: string;
    OPEN_BANKING: string;
    DOCUMENTS: string;
    REQUEST_DOCUMENTS: string;
    GENERAL_INFORMATION: string;
  };
}

let config: IConfig = { ...dev };
const env = process.env.REACT_APP_ENV;
switch (env) {
  case 'dev':
    config = dev;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    break;
}

export const isDevEnv = () => process.env.REACT_APP_ENV === 'dev';

export default config;
