export const enUS_consent = {
  'app.consent.header': 'Consent & Bank Verification',
  'app.consent.subHeader':
    'We need to collect the information outlined below, it will only be used to verifiy your bank details.  Please select the data you would like to share.',
  'app.consent.footer': 'I consent to this information being used',
  'app.consent.title.contact': 'Business Details',
  'app.consent.text.contact': 'This will allow us to access your business information',
  'app.consent.title.account': 'Account Details',
  'app.consent.text.account': 'This will allow us to access your account information',
  'app.consent.title.note':
    'By clicking ‘Next’ you will be redirected to our third party verification service and will be prompted to login to your corresponding banking provider to allow us to verify your details. Alternatively if you would like to provided a manual verification by providing bank documents please {link}.',
  'app.consent.list.abn': 'ABN',
  'app.consent.list.acn': 'ACN',
  'app.consent.list.legalName': 'Legal Name',
  'app.consent.list.accountName': 'Account Name',
  'app.consent.list.bsb': 'Account BSB',
  'app.consent.list.accountNumber': 'Account Number',
  'app.consent.notice':
    'Your data will be retained only for this single verification process, once you have completed the verificaiton all data will be deleted.',
  'app.consent.click.here': 'click here',
  'app.consent.moreInfo': 'Hi {name}, could you provide more bank statements from last financial year please.',
};
