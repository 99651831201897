export const enUS_title = {
  'title.business.details': 'Business Details',
  'title.contact.details': 'Contact Details',
  'title.introduction': 'Introduction',
  'title.payment.detail': 'Payment Details',
  'title.consent': 'Consent',
  'title.verify.details': 'Compare Details',
  'title.notFound': 'Page Not Found',
  'title.pendingVerification': 'Pending Verification',
  'title.verificationConfirmation': 'Verification Confirmation',
  'title.passwordReset': 'Password Reset',
  'title.redirect': 'Redirect',
  'title.createPassword': 'Create Password',
  'title.verifyConsent': 'Verify Consent',
  'title.manual.upload': 'Upload Supporting Documents',
  'title.questionnaire': 'Questionnaire',
  'title.resubmittingDocuments': 'Reupload Supporting Documents',
  'title.verificationProvided': 'Verification Provided',
};
