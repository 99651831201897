import React, { lazy, FC, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import RouteWrapper from './RouteWrapper';
import LayoutPage from 'components/layout/Layout';
import Spinner from 'components/spinner/Spinner';

const NotFound = lazy(() => import('../pages/NotFound'));
const BusinessDetailsPage = lazy(() => import('../pages/business'));
const ConsentPage = lazy(() => import('../pages/consent'));
const ContactPage = lazy(() => import('../pages/contact'));
const IntroductionPage = lazy(() => import('../pages/introduction'));
const PaymentPage = lazy(() => import('../pages/payment'));
const PendingVerificationPage = lazy(() => import('../pages/pendingVerification'));
const PendingVerificationUploadPage = lazy(() => import('../pages/pendingVerificationUpload'));
const VerificationConfirmationPage = lazy(() => import('../pages/verificationConfirmation'));
const VerifyPage = lazy(() => import('../pages/verify'));
const ResetPasswordPage = lazy(() => import('pages/resetPassword'));
const RedirectPage = lazy(() => import('pages/redirect'));
const CreatePasswordPage = lazy(() => import('pages/createPassword/CreatePassword'));
const VerifyConsent = lazy(() => import('pages/redirect/VerifyConsent'));
const UploadDocuments = lazy(() => import('pages/uploadDocuments'));
const Questionnaire = lazy(() => import('pages/questionnaire'));
const ResubmitDocuments = lazy(() => import('pages/resubmitDocuments'));
const VerificationProvidedPage = lazy(() => import('pages/verificationProvided/VerificationProvided'));

const routeList: PartialRouteObject[] = [
  {
    path: '',
    element: <RouteWrapper element={<LayoutPage />} titleId="" />,
    children: [
      {
        path: 'introduction',
        element: <RouteWrapper element={<IntroductionPage />} titleId="title.introduction" />,
      },
      {
        path: 'contact-details',
        element: <RouteWrapper element={<ContactPage />} titleId="title.contact.details" />,
      },
      {
        path: 'consent',
        element: <RouteWrapper element={<ConsentPage />} titleId="title.consent" />,
      },
      {
        path: 'business-details',
        element: <RouteWrapper element={<BusinessDetailsPage />} titleId="title.business.details" />,
      },
      {
        path: 'payment-details',
        element: <RouteWrapper element={<PaymentPage />} titleId="title.payment.detail" />,
      },
      {
        path: 'verify',
        element: <RouteWrapper element={<VerifyPage />} titleId="title.verify.details" />,
      },
      {
        path: 'upload-documents',
        element: <RouteWrapper element={<UploadDocuments />} titleId="title.manual.upload" />,
      },
      {
        path: 'pending-verification',
        element: <RouteWrapper element={<PendingVerificationPage />} titleId="title.pendingVerification" />,
      },
      {
        path: 'pending-verification-supplier',
        element: <RouteWrapper element={<PendingVerificationUploadPage />} titleId="title.pendingVerification" />,
      },
      {
        path: 'verification-confirmation',
        element: <RouteWrapper element={<VerificationConfirmationPage />} titleId="title.verificationConfirmation" />,
      },
      {
        path: 'password-reset',
        element: <RouteWrapper element={<ResetPasswordPage />} titleId="title.passwordReset" />,
      },
      {
        path: 'new-password',
        element: <RouteWrapper element={<CreatePasswordPage />} titleId="title.createPassword" />,
      },
      {
        path: 'questionnaire',
        element: <RouteWrapper element={<Questionnaire />} titleId="title.questionnaire" />,
      },
      {
        path: 'request-document',
        element: <RouteWrapper element={<ResubmitDocuments />} titleId="title.resubmittingDocuments" />,
      },
      {
        path: 'verification-provided',
        element: <RouteWrapper element={<VerificationProvidedPage />} titleId="title.verificationProvided" />,
      },
      {
        path: '*',
        element: <RouteWrapper element={<NotFound />} titleId="title.notFound" />,
      },
    ],
  },
  {
    path: 'redirect',
    element: <RouteWrapper element={<RedirectPage />} titleId="title.redirect" />,
  },
  {
    path: 'verify-consent',
    element: <RouteWrapper element={<VerifyConsent />} titleId="title.verifyConsent" />,
  },
];

const RenderRouter: FC = () => {
  const element = useRoutes(routeList);
  return <Suspense fallback={<Spinner />}>{element}</Suspense>;
};

export default RenderRouter;
