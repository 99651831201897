import { createStore, createHook } from 'react-sweet-state';
import { IApiStoreStates, StoreApi } from './api.d';
export const API_STORE = 'ApiStore';

type Actions = typeof actions;

export const initialState: IApiStoreStates = {
  loaded: true,
  fetching: false,
  error: false,
  data: null,
  faults: '',
  initiated: false,
  params: null,
  method: '',
  url: '',
  fetchingAction: '',
};

export const actions = {
  initData: (payload: IApiStoreStates) => ({ setState }: StoreApi) => {
    setState({ ...payload });
  },
  setStore: (payload: IApiStoreStates) => ({ setState, getState }: StoreApi) => {
    const prevStates = getState();
    setState({ ...prevStates, ...payload });
  },
  setData: (newData: any = null) => ({ setState, getState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      data: newData,
    });
  },
  setFetching: (isFetching: boolean = false) => ({ getState, setState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      fetching: isFetching,
    });
  },
  setParams: (params: any = null) => ({ setState, getState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      params,
    });
  },
  setMethod: (method: string = '') => ({ setState, getState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      method,
    });
  },
  setError: (error: boolean = false) => ({ getState, setState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      error,
    });
  },
  setFaults: (payload: string = '') => ({ setState, getState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      faults: payload,
    });
  },
  setLoaded: (payload: boolean = false) => ({ getState, setState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      loaded: payload,
    });
  },
  setUrl: (payload: string = '') => ({ getState, setState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      url: payload,
    });
  },
  setFetchingAction: (payload: string = '') => ({ setState, getState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      fetchingAction: payload,
    });
  },
};

const Store = createStore<IApiStoreStates, Actions>({ initialState, actions, name: API_STORE });
const useApiStore = createHook(Store);

export default useApiStore;
