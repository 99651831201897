import React, { FC, useLayoutEffect, ComponentType, useState } from 'react';
import { initialState as initialStoreStates, AppContainer, storeKey } from '../application/application';
import databases from '../../cache';

const withPersistedApp = <P extends object>(Component: ComponentType<P>): FC<P> => ({ ...props }: any) => {
  const [storePersisted, setStorePersisted] = useState(initialStoreStates);
  useLayoutEffect(() => {
    (async () => {
      const data = await databases.getItem(storeKey).catch((error: Error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
      if (data) {
        setStorePersisted({
          ...data,
          initiated: true,
        });
      } else {
        setStorePersisted({
          ...initialStoreStates,
          initiated: true,
        });
      }
    })();
  }, []);
  if (storePersisted && !storePersisted.initiated) return null;
  return (
    <AppContainer isGlobal={true} initialState={storePersisted}>
      <Component {...props} />
    </AppContainer>
  );
};

export default withPersistedApp;
