export enum API_METHODS {
  GET = 'get',
  POST = 'post',
  DELETE = 'delete',
  PUT = 'put',
  PATCH = 'patch',
}

export enum STEPS {
  INTRODUCTION,
  CONTACT,
  BUSINESS,
  PAYMENT,
  CONSENT,
  VERIFY,
  QUESTIONNAIRE,
}

export enum COOKIE_KEYS {
  SUB_DOMAIN = 'sub-domain',
  CUSTOMER_NAME = 'customer-name',
}

export enum ONBOARDING_FLOW {
  MANUAL = 'manual',
  BANKING = 'banking',
}

export enum QUESTIONNAIRE_TYPE {
  LONG_TEXT = 'LONG_TEXT',
  SHORT_TEXT = 'SHORT_TEXT',
  RADIO = 'RADIO',
  DATE = 'DATE',
  CHECKBOX = 'CHECKBOX',
  UPLOAD = 'UPLOAD',
}

export enum QUESTIONNAIRE_STATUS {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
}
