export const enUS_questionnaire = {
  'questionnaire.title': 'Question Page Header',
  'questionnaire.subTitle':
    'Question Page Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
  'questionnaire.textField.text': 'Answer your question',
  'questionnaire.selectDate': 'Select Date',
  'questionnaire.browse': 'Click ‘Browse Files’ or drag and drop in this area.',
  'questionnaire.maximum': 'A total of :fileNumber files can be uploaded with a maximum file size of 20mb',
  'questionnaire.required': 'The field is mandatory',
};
