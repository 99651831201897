import React, { FC } from 'react';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { useIntl } from 'react-intl';

export interface RouteWrapperProps extends RouteProps {
  /** document title locale id */
  titleId: string;
  /** authorization？ */
  auth?: boolean;
}

const RouteWrapper: FC<RouteWrapperProps> = ({ titleId, auth, ...props }) => {
  const { formatMessage } = useIntl();
  if (titleId) {
    document.title = formatMessage({
      id: titleId,
    });
  }
  return <Route {...props} />;
};

export default RouteWrapper;
