import React from 'react';
import { Layout } from 'antd';
import logo from 'assets/logoBottom.png';
import { ILayout } from 'components/layout/Layout.d';

const { Footer } = Layout;

const FooterComponent = ({ logoCustomer }: ILayout) => {
  return <>{logoCustomer !== '' && <Footer className="layout-page-footer">{<img src={logo} alt="Logo" />}</Footer>}</>;
};

export default FooterComponent;
