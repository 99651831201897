import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import RenderRouter from './routes';
import withPersistedApp from 'stores/hoc/withPersistedApp';
import { localeConfig } from 'locales';
import 'stores/middlewares/logger';
import 'stores/middlewares/persistent';

function App() {
  const locale = 'en_US';
  useEffect(() => {
    if (locale === 'en_US') {
      moment.locale('en');
    }
  }, [locale]);
  const getAntdLocale = () => {
    if (locale === 'en_US') {
      return enUS;
    }
  };
  return (
    <ConfigProvider locale={getAntdLocale()} componentSize="middle">
      <IntlProvider locale={locale.split('_')[0]} messages={localeConfig[locale]}>
        <BrowserRouter>
          <RenderRouter />
        </BrowserRouter>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default withPersistedApp(App);
