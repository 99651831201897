export const enUS_upload = {
  'upload.title': 'Upload Supporting Documents',
  'upload.subTitle':
    'You will need to provide supporting documents in the form of the last three bank statement for a manual verification process',
  'pdf.upload.accept': 'This file type is not valid. Only PDF (.pdf) file types are permitted.',
  'pdf.upload.browse': 'Click ‘Browse Files’ or drag and drop in this area',
  'pdf.upload.limit': 'The file you are uploading is too large. Allowed maximum size is {limit} MB.',
  'upload.browse.button': 'Browse Files',
  'pending.email.title.supplier': 'Pending Verification',
  'pending.email.subTitle.supplier':
    'Thank you for providing your supplier details, your documents have been sent to {name} for review, you can now close this window',
};
