import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import useAppStore from 'stores/application/application';
import useApi from 'api/useApi';
import CONFIG from 'config';
import { API_METHODS } from 'constants/enum';
import { useLocation } from 'react-router-dom';
import Spinner from 'components/spinner/Spinner';
import ROUTES from 'routes/routes';
import { useNavigate } from 'react-router-dom';

import './Layout.scss';

const { Content } = Layout;
const LayoutPage = () => {
  const [appStates, actions] = useAppStore();

  const search = useLocation().search;
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const uuid = params.get('uuid') || appStates.uuid || '';
  const [logo, setLogo] = useState('');

  const EXTEND_PAGES = [
    ROUTES.VERIFICATION_PROVIDED,
    ROUTES.PENDING_VERIFICATION_SUPPLIER,
    ROUTES.PENDING_VERIFICATION,
  ];

  const { data, loading, setCurrentParams } = useApi({
    url: `${CONFIG.API.GENERAL_INFORMATION.replace(':uuid', uuid)}`,
    loadInitialState: true,
    method: API_METHODS.GET,
  });

  useEffect(() => {
    if (!EXTEND_PAGES.includes(pathname)) {
      setCurrentParams({});
    }
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    actions.setData({
      ...appStates,
      step: data?.data?.totalSteps ?? 5,
    });
    setLogo(
      data?.data?.logo
        ? `data:image/${
            data?.data?.logo?.type && data?.data?.logo?.type.toLowerCase() === 'svg'
              ? 'svg+xml'
              : data?.data?.logo?.type
          };base64,${data?.data?.logo?.based64Image}`
        : '',
    );

    if (data?.data?.completed) {
      navigate(ROUTES.VERIFICATION_PROVIDED);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Layout className="layout-page">
      {loading && <Spinner />}
      <Header logoCustomer={logo} />
      <Content className="layout-page-content">
        <Outlet />
      </Content>
      <Footer logoCustomer={logo} />
    </Layout>
  );
};

export default LayoutPage;
