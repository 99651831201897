import { IConfig } from './index';

const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST || 'http://localhost:3004';
const OPENBANKING_SERVICE = process.env.REACT_APP_OPENBANKING_SERVICE || 'http://localhost:3004';

const CONFIG: IConfig = {
  API: {
    USER_SERVICE_LOGIN: `${REACT_APP_API_HOST}/auth/signin`,
    USER_SERVICE_SIGNUP: `${REACT_APP_API_HOST}/auth/signup`,
    CONTACT: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/contact-detail`,
    CONSENT: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/consent`,
    VERIFIED: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/verify-consent`,
    ABR_LOOKUP: `${REACT_APP_API_HOST}/api/onboarding/v2/abr-lookup`,
    ONBOARDING: `${REACT_APP_API_HOST}/api/onboarding/v2`,
    TERM_ACCEPT: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/term-accept`,
    BUSINESS_DETAILS: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/business-detail`,
    PAYMENT_DETAILS: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/payment-detail`,
    VERIFY_DETAILS: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/verified-detail`,
    VERIFY_COMPLETE: `${REACT_APP_API_HOST}/api/onboarding/v2/verification/complete`,
    RESET_PASSWORD: `${REACT_APP_API_HOST}/api/onboarding/v2/reset-password`,
    NEW_PASSWORD: `${REACT_APP_API_HOST}/api/onboarding/v2/new-password`,
    CONSENT_MANUAL: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/consent-manual`,
    UPLOAD: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/upload-banking-statement`,
    VALIDATE_BSB: `${REACT_APP_API_HOST}/api/onboarding/v2/validate-bsb`,
    CONSENT_AUTHORIZATION: `${OPENBANKING_SERVICE}/openbanking/onboarding/v2/consent/authorization`,
    CONSENT_ACTIVE: `${OPENBANKING_SERVICE}/openbanking/onboarding/v2/consent/active`,
    OPENBANKING_VERIFIED_DETAIL: `${OPENBANKING_SERVICE}/openbanking/onboarding/v2/verified-detail`,
    QUESTIONNAIRE: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/questionnaire`,
    OPEN_BANKING: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/open-banking`,
    DOCUMENTS: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/documents`,
    REQUEST_DOCUMENTS: `${REACT_APP_API_HOST}/api/onboarding/v2/resubmit-document`,
    GENERAL_INFORMATION: `${REACT_APP_API_HOST}/api/onboarding/v2/:uuid/information`,
  },
};

export default CONFIG;
