import { useState, useEffect } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import { TUseApi } from './useApi.d';
import { API_METHODS } from 'constants/enum';
import API, { DEFAULT_ERROR_MESSAGE } from './index';
import useApiStore from 'stores/api/api';

const useApi: TUseApi = ({
  url,
  initialValue = [],
  params = {},
  payload = '',
  headers = {},
  loadInitialState = false,
  method = API_METHODS.GET,
}) => {
  const [data, setData] = useState(initialValue);
  const [currentInitialState, setCurrectInitialState] = useState(loadInitialState);
  const [currentUrl, setCurrentUrl] = useState(url);
  const [currentMethod, setCurrentMethod] = useState(method);
  const [currentParams, setCurrentParams] = useState(params);
  const [currentPayload, setCurrentPayload] = useState(payload);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const cancelTokenSource = axios.CancelToken.source();
  const [state, actions] = useApiStore();

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async function () {
      const newStore = {
        ...state,
        fetching: true,
        error: false,
        faults: '',
        url: currentUrl,
      };
      try {
        actions.setStore(newStore);
        setLoading(true);
        setErrorMsg('');
        const response = await API({
          url: currentUrl,
          params: currentParams,
          method: currentMethod,
          headers,
          data: currentPayload,
          cancelTokenSource,
        });
        if (isSubscribed) {
          setData(response);
          actions.setStore({
            ...state,
            fetching: false,
            error: false,
            data: response,
            faults: '',
          });
        }
      } catch (error) {
        const msg = get(error, 'response.data.userMessage', DEFAULT_ERROR_MESSAGE);
        setErrorMsg(msg);
        if (
          ((Object.keys(error).length > 2 && msg !== DEFAULT_ERROR_MESSAGE) || Object.keys(error).length === 1) &&
          isSubscribed
        ) {
          actions.setError(true);
          actions.setFaults(msg);
          actions.setFetching(false);
        }
      } finally {
        actions.setLoaded(true);
        setLoading(false);
      }
    };
    if (currentInitialState) {
      fetchData();
    }
    return () => {
      actions.setStore({
        fetching: false,
      });
      cancelTokenSource && cancelTokenSource.cancel();
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, [currentParams, currentUrl, currentMethod, currentPayload, actions]);
  return {
    errorMsg,
    loading,
    data,
    currentMethod,
    state,
    actions,
    setCurrentUrl,
    setCurrentParams,
    setCurrectInitialState,
    setCurrentMethod,
    setCurrentPayload,
  };
};

export default useApi;
