export const enUS_introduction = {
  'introduction.title': '{name} Supplier Application',
  'introduction.subTitle': 'Thank you for applying to be a supplier for {name}',
  'introduction.value.verify': 'Verify your bank details within 5 minutes',
  'introduction.value.secure': 'Secure connection to provide peace of mind',
  'introduction.card.title': 'What you will need to complete this form',
  'introduction.card.content.criteria1': 'Contact details for who is filling out this application',
  'introduction.card.content.criteria2': 'A valid email address for the above',
  'introduction.card.content.criteria3': 'Business details including ABN, or ACN',
  'introduction.card.content.criteria4': 'Bank payment details',
  'introduction.card.content.criteria5': 'Agree to the consent of how we will use your data',
  'introduction.card.content.criteria6': 'Business bank login details for third party verification',
  'introduction.button.agree': 'I Agree',
  'introduction.button.disagree': 'I Disagree',
  'introduction.termsAndConditions': 'PayOK Terms and Conditions',
  'introduction.policy': 'PayOK Privacy Policy',
  'introduction.note': 'By clicking ‘I Agree’ you agree to have read and consent to the {term} and {privacy}.',
};
