import React from 'react';
import { Spin } from 'antd';
import './Spinner.scss';

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <Spin />
    </div>
  );
};

export default Spinner;
