import { enUS_title } from './global/title';
import { enUS_contact } from './contact/index';
import { enUS_introduction } from './introduction';
import { enUS_business } from './business';
import { enUS_common } from './global';
import { enUS_validation } from './global/validation';
import { enUS_consent } from './consent/index';
import { enUS_payment } from './payment';
import { enUS_confirmation } from './confirm';
import { enUS_pending } from './pending';
import { enUS_verify } from './verify';
import { enUS_password } from './password';
import { enUS_upload } from './upload';
import { enUS_questionnaire } from './questionnaire';

const en_US = {
  ...enUS_title,
  ...enUS_introduction,
  ...enUS_contact,
  ...enUS_common,
  ...enUS_business,
  ...enUS_validation,
  ...enUS_consent,
  ...enUS_payment,
  ...enUS_confirmation,
  ...enUS_pending,
  ...enUS_verify,
  ...enUS_password,
  ...enUS_upload,
  ...enUS_questionnaire,
};

export default en_US;
