import { defaults, StoreState } from 'react-sweet-state';
import databases from '../../cache';
import { APP_STORE } from '../application/application';

const WHITE_LIST: string[] = [APP_STORE];

export const persistent = (storeState: StoreState<any>) => (next: any) => (fn: any) => {
  const result = next(fn);
  const { key } = storeState;
  const isInWhiteList = WHITE_LIST.filter((store) => key.includes(store)).length > 0;

  if (isInWhiteList) {
    const state = storeState.getState();
    databases.setItem(storeState.key, state).catch((error: Error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }
  return result;
};

defaults.middlewares.add(persistent);
