export const enUS_password = {
  'password.title': 'Password Reset',
  'password.subTitle': 'Please enter your email address to request a password reset link',
  'password.email': 'Email',
  'password.email.placeholder': 'Enter your email...',
  'password.send': 'Send',
  'create.password.title': 'Create your password',
  'create.password.subTitle': 'Please enter your new login password, and keep it safe :-)',
  'create.password.password': 'Password',
  'create.password.confirmPW': 'Confirm Password',
  'create.password.submit': 'Submit',
  'create.password.invalid': 'Invalid password',
  'create.password.required': 'The password field is required',
  'create.password.confirmPW.required': 'The Confirm password field is required',
  'create.password.pwNotMatch': 'Password does not match',
  'create.password.success.msg':
    'A reset password link was sent to your email, if the submitted email corresponds to an existing account.',
  'create.password.goto': 'Already have a password?',
  'create.password.success':
    'Your password has been successfully updated.\n You can now access your Consent History with your email and password.',
  'create.password.loginHere': 'Login here',
};
