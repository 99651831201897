export const enUS_payment = {
  'payment.title': 'Payment Details',
  'payment.subTitle': 'We need to collect your businesses payment information to verify against your bank',
  'payment.accountName': 'Account Name',
  'payment.bsb': 'BSB',
  'payment.accountNumber': 'Account Number',
  'payment.accountName.placeholder': 'Enter Account Name...',
  'payment.bsb.placeholder': 'Enter BSB...',
  'payment.accountNumber.placeholder': 'Enter Account Number...',
};
