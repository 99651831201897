export const enUS_business = {
  'business.title': 'Business Details',
  'business.subTitle': 'We need to collect the company information to verify your business details',
  'business.abn': 'ABN',
  'business.acn': 'ACN',
  'business.legalName': 'Legal Name',
  'business.abn.placeholder': 'Enter ABN...',
  'business.acn.placeholder': 'Enter ACN...',
  'business.legalName.placeholder': 'Enter Legal Name...',
};
