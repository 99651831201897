export const enUS_contact = {
  'app.contact.header': 'Contact Details',
  'app.contact.subHeader':
    'We need to collect contact information to verify the details of the individual completing this form',
  'app.contact.progress': '1 of 5',
  'app.contact.firstName': 'First Name',
  'app.contact.lastName': 'Last Name',
  'app.contact.contactNumber': 'Contact Number',
  'app.contact.email': 'Email Address',
  'app.contact.button.back': 'Back',
  'app.contact.button.next': 'Next',
  'app.contact.error.required': 'This field is required',
  'app.contact.error.email': 'The entered email is invalid ',
  'app.contact.placeholer.firstName': 'Enter first name...',
  'app.contact.placeholer.lastName': 'Enter last name...',
  'app.contact.placeholer.contactNumber': 'Enter contact number...',
  'app.contact.placeholer.email': 'Enter email address...',
};
