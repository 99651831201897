import { createStore, createHook, createContainer } from 'react-sweet-state';
import { IApplicationStoreStates, StoreApi } from './application.d';

type Actions = typeof actions;
export const APP_STORE = 'AppStore';
export const initialState: IApplicationStoreStates = {
  epoch: 0,
  uuid: '',
  initiated: false,
  apiKey: '',
  auth0Token: '',
  nonce: '',
  consentId: '',
  openBankingKey: '',
  logo: '',
  step: 5,
};
export const actions = {
  setData: (payload: IApplicationStoreStates) => ({ setState }: StoreApi) => {
    setState({ ...payload });
  },
  clearData: () => ({ setState }: StoreApi) => {
    setState(initialState);
  },
  setId: (uuid: string) => ({ setState, getState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      uuid,
    });
  },
  setEpoch: (epoch: number) => ({ setState, getState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      epoch,
    });
  },
  setParams: ({
    auth0Token,
    nonce,
    openBankingKey,
    consentId,
    uuid,
    apiKey,
  }: {
    auth0Token: string;
    nonce: string;
    openBankingKey: string;
    consentId: string;
    uuid: string;
    apiKey: string;
  }) => ({ setState, getState }: StoreApi) => {
    const states = getState();
    setState({
      ...states,
      auth0Token,
      nonce,
      openBankingKey,
      consentId,
      uuid,
      apiKey,
    });
  },
};

export const Store = createStore<IApplicationStoreStates, Actions>({
  initialState,
  actions,
  name: APP_STORE,
});

const useAppStore = createHook(Store);
export const storeKey = `${Store.key.join('__')}@__global__`;
type StoreContainerProps = {
  initialState: IApplicationStoreStates;
};
export const AppContainer = createContainer<IApplicationStoreStates, Actions, StoreContainerProps>(Store, {
  onInit: () => ({ setState }: StoreApi, { initialState }) => {
    setState(initialState);
  },
});
export default useAppStore;
