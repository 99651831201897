const Routes = {
  INTRODUCTION: '/introduction',
  CONTACT_DETAILS: '/contact-details',
  BUSINESS_DETAILS: '/business-details',
  CONSENT: '/consent',
  PAYMENT_DETAILS: '/payment-details',
  PENDING_VERIFICATION: '/pending-verification',
  VERIFICATION_CONFIRMATION: '/verification-confirmation',
  VERIFY_DETAILS: '/verify',
  PAGE_NOT_FOUND: '/page-not-found',
  PASSWORD_RESET: '/password-reset',
  REDIRECT: '/redirect',
  VERIFY_CONSENT: '/verify-consent',
  UPLOAD_DOCUMENTS: '/upload-documents',
  PENDING_VERIFICATION_SUPPLIER: '/pending-verification-supplier',
  QUESTIONNAIRE: '/questionnaire',
  RESUBMIT_DOCUMENTS: '/request-document',
  VERIFICATION_PROVIDED: '/verification-provided',
};

export default Routes;
