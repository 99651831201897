import React from 'react';
import { Layout } from 'antd';
import logo from 'assets/logo.png';
import { ILayout } from 'components/layout/Layout.d';

const { Header } = Layout;
const HeaderComp = ({ logoCustomer }: ILayout) => {
  return (
    <Header className="layout-page-header">
      {logoCustomer !== '' ? (
        <img alt="Logo" src={logoCustomer} className="layout-page-header-img" />
      ) : (
        <>
          <div>APPLICATIONS BY</div>
          <img src={logo} alt="Logo" />
        </>
      )}
    </Header>
  );
};

export default HeaderComp;
