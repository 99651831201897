import axios from 'axios';
import { get, includes } from 'lodash';
import { TApi } from './index.d';
import CONFIG from 'config';
import { Store } from 'stores/application/application';
import { defaultRegistry } from 'react-sweet-state';

let isOnline: boolean = navigator.onLine;

window.addEventListener('offline', () => {
  isOnline = false;
});

window.addEventListener('online', () => {
  isOnline = true;
});

const getApiKeyFromStore = async () => {
  const auth = await defaultRegistry.getStore(Store);
  const { storeState } = auth;
  const state = storeState.getState();
  return state.apiKey;
};

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';
const nonHeaderKey = [
  CONFIG.API.VERIFY_COMPLETE,
  CONFIG.API.RESET_PASSWORD,
  CONFIG.API.NEW_PASSWORD,
  CONFIG.API.VALIDATE_BSB,
  CONFIG.API.CONSENT_ACTIVE,
  CONFIG.API.CONSENT_AUTHORIZATION,
  CONFIG.API.OPENBANKING_VERIFIED_DETAIL,
];
const api: TApi = async ({ url, params = '', method = 'get', headers = {}, data = '', cancelTokenSource }) => {
  const apiKey = await getApiKeyFromStore();
  const urlHeaderKey = includes(nonHeaderKey, url)
    ? {}
    : {
        'api-key': `${apiKey}`,
      };

  let newHeaders;

  if (apiKey) {
    newHeaders = {
      ...urlHeaderKey,
      ...headers,
    };
  } else {
    newHeaders = {
      ...headers,
    };
  }

  try {
    const response = await axios({
      method,
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...newHeaders,
      },
      params,
      data,
      cancelToken: cancelTokenSource?.token,
    });
    return response && response.data;
  } catch (error) {
    const userMessage =
      get(error, 'response.data.userMessage', '') ||
      get(error, 'response.data.message', '') ||
      get(error, 'response.data.error', '') ||
      get(error, 'response.data.errorMessage', '') ||
      DEFAULT_ERROR_MESSAGE;
    const response = get(error, 'response', {});
    const dataResponse = get(error, 'response.data', {});
    if (isOnline) {
      const errObject = {
        ...error,
        response: {
          ...response,
          data: {
            ...dataResponse,
            userMessage: `${userMessage}`,
          },
        },
      };

      throw errObject;
    } else {
      const offlineResponse = {
        response: {
          data: {
            userMessage: 'Unable to process the request',
          },
        },
      };

      throw offlineResponse;
    }
  }
};

export default api;
