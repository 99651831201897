export const enUS_confirmation = {
  'confirmation.title': 'Bank Verification Successful',
  'confirmation.subTitle':
    'Thank you for verifying your bank account details and confirming your email address. Your verification is complete and can now close this window.',
  'confirmation.expired.title': 'Verification Expired',
  'confirmation.expired.subTitle': 'The unique link you have provided is no longer active',
  'verificationProvided.title': 'Verification Provided',
  'verificationProvided.subTitle':
    'It appears you have already provided your supplier details, you can now close this window',
};
