/* eslint-disable no-console */
import { defaults, StoreState } from 'react-sweet-state';
import { isDevEnv } from '../../config';

export const logger = (storeState: StoreState<any>) => (next: any) => (fn: any) => {
  console.groupCollapsed('Store', storeState.key);
  // keep comment code to check middleware with store
  const result = next(fn);
  console.groupEnd();
  return result;
};

if (isDevEnv()) {
  defaults.middlewares.add(logger);
}
