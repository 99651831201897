export const enUS_validation = {
  'global.validation.required': 'This field is required',
  'global.validation.abn.length': 'ABN must be 11 digits long',
  'global.validation.acn.length': 'ACN must be 09 digits long',
  'global.validation.bsb.length': 'BSB must be a 6 digit number',
  'global.validation.notMatch': 'This field should exactly match the verified information',
  'global.validation.key': 'Unauthorized',
  'global.validation.key.invalid': 'Invalid onboarding information ',
  'global.validation.accountNumber.length': 'Account Number must not longer than 9 digits',
};
